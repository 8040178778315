import React from "react"
import FotoSavar from "@components/pageCasosdeExito/image/imgPhotoEmpresa/savar/FotoSavar.png"
import FotoSavar2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/savar/FotoSavar2.png"

//logo de empresas clientes
import Savar from "@components/pageCasosdeExito/image/logoscustomer/Savar.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import integrar from "@components/pageCasosdeExito/image/iconcards/integrarApi.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/savar/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/savar/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/savar/img3.svg"

const text1 = (
  <span className="">
    Savar, es una empresa líder en logística, especialista en soluciones Door to
    Door. La compañía cuenta con soluciones logísticas de última milla,
    fulfillment, ecommerce y más para diferentes verticales de negocios.
    <br /> <br />
    La corporación logística ha mejorado en +20% el aumento de satisfacción de
    sus clientes con la ayuda de Beex, gracias a la automatización de estados
    de sus pedidos a través de notificaciones por WhatsApp Business API.
  </span>
)

const text2 = (
  <span>
    Savar, compañía logística de las más importantes en Perú con más de 40 años
    de existencia al servicio de sus clientes, cuenta con más de 1,000
    colaboradores cuya misión es satisfacer la experiencia de sus clientes para
    sus diferentes verticales de negocio.
    <br /> <br />
    Caracterizados por su constante innovación, son reconocidos por su
    infraestructura, procesos y tecnología. Contando con más de 300 camiones de
    transporte, +500km2 de almacenes y más infraestructura importante, cuenta
    con cobertura a nivel nacional en sus soluciones integrales.
    <br /> <br />
    Dinkar Rios, Jefe de Sistemas de Savar, ha sido un contacto importante para
    la implementación del API de WhatsApp Business en su operación de servicio
    al cliente, a través de Beex.
    <br /> <br />
    El objetivo principal de la compañía era mejorar la experiencia de sus
    clientes para las entregas realizadas, por lo que decidieron enviar
    notificaciones de estados de éstas a través de WhatsApp, el cuál es el canal
    más usado por los usuarios en general.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex es fundamental en nuestra operación diaria. Nos permite interactuar
    con nuestros clientes de manera más eficiente y en tiempo real. Hemos
    reducido significativamente los tiempos de espera para confirmaciones y
    mejorado la experiencia del cliente en general.”
  </p>
)

const text4 = (
  <span>
    Considerando esto, el paso principal inicial para lograr esta automatización
    era integrar Beex con el ERP de la compañía. Esto se logró de manera
    óptima gracias al acompañamiento del equipo de soporte de Beex, junto
    con el equipo de TI de Savar. Con esta integración ya estábamos listo para
    iniciar el flujo de automatización con el API de WhatsApp Business.
  </span>
)

const text5 = (
  <span>
    Savar, con la consigna de mejorar el proceso de notificaciones a sus
    clientes y eliminar las consultas repetitivas acerca de sus envíos, decidió
    apoyarse en el canal de más uso de sus usuarios para notificar acerca de sus
    estados.
    <br /> <br />
    Es por lo que el API de WhatsApp Business fue fundamental para este fin. Con
    ella, Savar logró integrar su base de datos y, con esto, todas las señales
    en los cambios de estados de sus pedidos.
    <br /> <br />
    Y este ha sido otro punto importante en el proceso de implementación de
    Beex, la integración con su ERP. Como menciona Dinkar, se logró tener una
    sincronización perfecta entre plataformas, lo que logró optimizar sus
    operaciones internas y garantizar que la información que se envía a sus
    clientes sea precisa.
    <br /> <br />
    Además, uno de los puntos que reforzó la decisión de Savar de automatizar su
    proceso de notificaciones para sus pedidos, aparte de querer mejorar la
    experiencia de sus clientes, fue el querer eliminar los errores manuales de
    su operación.
  </span>
)
const text6 = (
  <span>
    “Con Beex y el API de WhatsApp Business, aseguramos que nuestros clientes
    reciban actualizaciones constantes y oportunas sobre el estado de sus
    pedidos, aumentando la confianza y obteniendo números importantes de mejora,
    como la reducción en +30% de las consultas repetitivas.”
  </span>
)

const text7 = (
  <span>
    Para todos los negocios y rubros, sabemos de la importancia que ha logrado
    tener WhatsApp para diferentes operaciones, ya sea en atención al cliente,
    marketing o ventas. Y en Savar eran conscientes de ello y apostaron por una
    implementación total del canal.
    <br /> <br />
    Como nos cuenta Dinkar y su equipo, WhatsApp es una plataforma ampliamente
    utilizada por sus clientes, lo que la convierte en un canal más que ideal
    para las comunicaciones con sus clientes.
    <br /> <br />
    Y no solo ello, ya que post-implementación confirmaron ellos mismos los
    números que era capaz de generar WhatsApp para su operación de logística,
    obteniendo mejoras significativas en las tasas de apertura y lectura de sus
    notificaciones, además de facilitar la interacción bidireccional con sus
    clientes.
    <br /> <br />
    Sin duda, WhatsApp se ha convertido en el canal perfecto para la compañía en
    sus operaciones de logística, logrando mejorar la experiencia de sus
    clientes y ahorrar tiempo operativo para sus equipos, ya que ahora todo el
    proceso de notificaciones y consultas está automatizado con Beex.
  </span>
)
const text8 = (
  <span>
    “Usamos WhatsApp debido a su penetración en el mercado y facilidad de uso.
    El reto principal fue la integración con nuestro ERP, pero una vez superado
    ello, hemos visto mejoras significativas en la eficiencia, reducción de
    errores y satisfacción del cliente.”
  </span>
)

const text9 = (
  <span>
    Como es costumbre en Beex, uno de los puntos que más importancia le
    damos es el acompañamiento y soporte a nuestros clientes, y con Savar se
    notó desde el inicio por la integración tan importante que debíamos lograr
    con su ERP.
    <br /> <br />
    Ambos equipos se alinearon en el objetivo y con la ayuda de los equipos de
    Customer Success y el KAM asigando a Savar, se logró un proceso gratificante
    de implementación de Beex.
    <br /> <br />
    Como nos cuenta Dinkar, el soporte del equipo de Beex ha sido muy
    bueno, estando siempre disponibles para ayudar y siendo proactivos en
    ofrecer soluciones y mejoras.
    <br /> <br />
    Además, refuerza el hecho de que antes de implementar la solución,
    enfrentaban desafíos de comunicación oportuna y la gestión eficiente de las
    consultas. Después de implementar Beex, se ha experimentado una
    transformación en la forma en que interactúan y sirven a sus clientes.
  </span>
)
const text10 = (
  <span>
    “Sin dudar, recomendaría a Beex por su compromiso, su capacidad para
    entender nuestras necesidades y su enfoque innovador con sus soluciones de
    comunicaciones. Son un socio valioso en nuestra integración con WhatsApp.”
  </span>
)

const text11 = (
  <p style={{ marginBottom: "70px" }}>
    Estos son algunos procesos con los que ayudamos
    a Savar en su operación de servicio al cliente.
  </p>
)

const text12 = (
  <p style={{ marginBottom: "70px" }}>
    Estamos felices de la implementación que se logró dar con Savar
    en beneficio de su operación de logística. Al ser una compañía grande de
    este tipo de operaciones, la escabalidad y soporte de soluciones debía estar
    a la altura, por lo que la experiencia ha sido enriquecedora para ambas
    compañías.
  </p>
)

const data = {
  intro: {
    image: Savar,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoSavar,
    text4: text4,
    title5: "Automatización en notificaciones con WhatsApp",
    text5: text5,
    text6: text6,
    title7: "WhatsApp en logística de última milla",
    text7: text7,
    text8: text8,
    fototwo: FotoSavar2,
    title9: "Acompañamiento total",
    text9: text9,
    text10: text10,
    title11: "Experiencia de soporte",
    text11: text11,
    text12: text12,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de servicio al cliente. Beex ha logrado mejorar el contacto y comunicación con sus clientes, optimizando su proceso de notificaciones automatizadas.",
    },
    {
      title: "Integraciones vía API",
      img: integrar,
      info:
        "Implementación de procesos automáticos, gracias a estructura de API abierta para integración con su CRM/ERP. Logrando automatizar su proceso de notificaciones.",
    },
  ],
}

export default data
